import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ItemsViewService {
    //TODO: change to signal
  private isGridView$ = new BehaviorSubject<boolean>(true);

    toggleView(): void {
    this.isGridView$.next(!this.isGridView$.value);
  }

  onChangeView(): Observable<boolean> {
    return this.isGridView$.asObservable();
  }

}
